var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tertiary elevation-5 text--lighten-2",attrs:{"page":_vm.currentPage,"items-per-page":_vm.itemsPerPage,"search":_vm.searched,"loading":_vm.data.isLoading,"items":_vm.data.items,"headers":_vm.data.headers,"item-key":"id","no-results-text":"Nenhum resultado encontrado","no-data-text":"Nenhum dado encontrado","checkbox-color":"accent","footer-props":{
      showFirstLastPage: false,
      prevIcon: 'mdi-arrow-left',
      nextIcon: 'mdi-arrow-right',
         'items-per-page-text':'Itens por página'
    }},on:{"!page-count":function($event){_vm.pagesCounter = $event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TableHeader',{attrs:{"selected":_vm.selected},on:{"new":function($event){return _vm.$emit('new')}}})]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando dados, por favor aguarde ")]},proxy:true},(_vm.$route.matched[1].name === 'properties')?{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_vm._v(" #"+_vm._s(item.id)+" ")]}}:null,{key:"item.image_profile",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{staticClass:"elevation-3",attrs:{"color":"grey lighten-2","size":"36"}},[(item.image_profile != '' && item.image_profile != null)?_c('img',{staticClass:"u-object-fit",attrs:{"src":item.image_profile}}):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")])],1)]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")]),(item.views > 0 && item.views < 10)?_c('label',{staticClass:"ml-1"},[_vm._v(_vm._s("0" + item.views))]):_c('label',{staticClass:"ml-1"},[_vm._v(_vm._s(item.views))])],1),_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"overline",attrs:{"color":item.status ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.status ? "Ativo" : "Inativo")+" ")])]}},{key:"item.property_status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"overline",attrs:{"color":_vm.setColorPropertyStatus(item.property_status)}},[_vm._v(" "+_vm._s(item.property_status)+" ")])]}},{key:"item.contact",fn:function(ref){
    var item = ref.item;
return [(item.is_whatsapp)?_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-whatsapp")]):_vm._e(),_vm._v(" "+_vm._s(item.contact)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(_vm.$route.matched[1].name === 'admin-clients')?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('viewMore', item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),_c('v-list-item-text',[_vm._v("Configurações do Site ")])],1):_vm._e(),(_vm.$route.matched[1].name === 'properties')?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('viewPublishedProperty', item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),_c('v-list-item-text',[_vm._v("Ver no Site ")])],1):_vm._e(),(_vm.$route.matched[1].name === 'properties')?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('addImages', item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-plus")])],1),_c('v-list-item-text',[_vm._v("Imagens ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error","small":"","left":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-text',[_vm._v(" Editar ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-text',[_vm._v(" Excluir ")])],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }