<template>
  <v-data-table
    v-model="selected"
    class="tertiary elevation-5 text--lighten-2"
    @page-count.capture="pagesCounter = $event"
    :page.sync="currentPage"
    :items-per-page="itemsPerPage"
    :search="searched"
    :loading="data.isLoading"
    :items="data.items"
    :headers="data.headers"
    item-key="id"
    no-results-text="Nenhum resultado encontrado"
    no-data-text="Nenhum dado encontrado"
    checkbox-color="accent"
    :footer-props="{
        showFirstLastPage: false,
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
           'items-per-page-text':'Itens por página'
      }"
  >
    <template v-slot:top>
      <TableHeader @new="$emit('new')" :selected="selected" />
    </template>

    <template v-slot:loading>
      Carregando dados, por favor aguarde
    </template>

    <template v-slot:[`item.id`]="{ item }" v-if="$route.matched[1].name === 'properties'">
      #{{item.id}}
    </template>

    <template v-slot:[`item.image_profile`]="{ item }">
      <v-avatar class="elevation-3" color="grey lighten-2" size="36">
        <img
          v-if="item.image_profile != '' && item.image_profile != null"
          class="u-object-fit"
          :src="item.image_profile"
        />

        <v-icon v-else color="primary">mdi-account</v-icon>
      </v-avatar>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <v-chip color="info">
        <v-icon small>mdi-eye</v-icon> 
        <label class="ml-1" v-if="item.views > 0 && item.views < 10">{{ "0" + item.views }}</label>
        <label class="ml-1" v-else>{{ item.views }}</label>
      </v-chip>
      {{ item.title }}
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip class="overline" :color="item.status ? 'success' : 'error'">
        {{ item.status ? "Ativo" : "Inativo" }}
      </v-chip>
    </template>

    <template v-slot:[`item.property_status`]="{ item }">
      <v-chip
        class="overline"
        :color="setColorPropertyStatus(item.property_status)"
      >
        {{ item.property_status }}
      </v-chip>
    </template>

    <template v-slot:[`item.contact`]="{ item }">
      <v-icon v-if="item.is_whatsapp" small color="success"
        >mdi-whatsapp</v-icon
      >
      {{ item.contact }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="$route.matched[1].name === 'admin-clients'"
            @click="$emit('viewMore', item)"
          >
            <v-list-item-icon>
              <v-icon small>mdi-open-in-new</v-icon>
            </v-list-item-icon>
            <v-list-item-text>Configurações do Site </v-list-item-text>
          </v-list-item>
          <v-list-item
            v-if="$route.matched[1].name === 'properties'"
            @click="$emit('viewPublishedProperty', item)"
          >
            <v-list-item-icon>
              <v-icon small>mdi-open-in-new</v-icon>
            </v-list-item-icon>
            <v-list-item-text>Ver no Site </v-list-item-text>
          </v-list-item>
          <v-list-item
            v-if="$route.matched[1].name === 'properties'"
            @click="$emit('addImages', item)"
          >
            <v-list-item-icon>
              <v-icon small>mdi-image-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-text>Imagens </v-list-item-text>
          </v-list-item>

          <v-list-item @click="$emit('edit', item)">
            <v-list-item-icon>
              <v-icon color="error" small left>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-text> Editar </v-list-item-text>
          </v-list-item>

          <v-list-item @click="$emit('delete', item)">
            <v-list-item-icon>
              <v-icon color="error" small>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-text> Excluir </v-list-item-text>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { eventbus } from "@/main.js";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableHeader: () => import("./TableHeader.vue"),
  },
  data: () => ({
    searched: null,
    selected: [],
    pagesCounter: 1,
    itemsPerPage: 10,
    currentPage: 1,
  }),
  methods: {
    setColorPropertyStatus(status) {
      var color = null;
      switch (status) {
        case "Disponível":
          color = "primary";
          break;

        case "Alugado":
          color = "warning";
          break;

        case "Vendido":
          color = "warning";
          break;

        case "Indisponível":
          color = "error";
          break;

        case "Oculto":
          color = "error";
          break;

        default:
          color = "primary";
          break;
      }

      return color;
    },
  },
  created() {
    eventbus.$on("searching", (value) => {
      this.searched = value;
    });
  },
};
</script>

<style scoped>
.u-object-fit {
  object-fit: cover;
}
.v-list-item__icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 8px;
  height: 30px;
  margin-bottom: 8px;
}
</style>